<template lang="pug">
.ui-checkbox(:class="[large && 'large', asGroup && 'as-group']")
  input(
    type="checkbox"
    :class="indeterminate && 'indeterminate'"
    :id="id"
    :checked="modelValue"
    :disabled="disabled"
    @change="$emit('update:modelValue', Boolean($event.target.checked))"
  )
  label(:for="id")
    slot
      | {{ label }}
      old-warning(v-if="hint || hintType" :label="hint" :type="hintType" :size="hintSize")
  span.message(v-if="message") {{ message }}
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import OldWarning from "@/components/ui/tooltip/OldWarning.vue";

export default defineComponent({
  name: "UiCheckbox",
  components: {
    OldWarning,
  },
  emits: [
    'update:modelValue',
  ],
  props: {
    modelValue: {},
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    large: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    asGroup: {
      type: Boolean,
      default: false,
    },
    hintType: {
      type: String as PropType<'info'|'question'>,
      default: '',
    },
    hintSize: {
      type: String as PropType<'small'|'default'>,
      default: 'default',
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';

.ui-checkbox {
  display: flex;
  flex-flow: column;
  gap: 8px;

  &.large {
    label {
      font-size: 14px;
    }
  }

  &.as-group {
    label {
      margin: 8px 0 !important;
    }
  }

  label {
    gap: 4px;
  }

  .message {
    @include label-13-16;
    padding-left: 28px;
    color: var(--main-placeholder-color);
  }
}
</style>
